import { useQuery } from '@tanstack/react-query'
import { useQuestionnaire } from './useQuestionnaire'
import type { MedRequest } from '@/types/data'
import { useFlag } from '@unleash/proxy-client-react'
import http from '@/lib/http'
import { queryClient } from '@/services/queryClient'

export const useConsultationAISummary = (medRequest?: MedRequest) => {
  const isFeatureFlagEnabled = useFlag('ai_dental')

  const isEnabled =
    medRequest?.orderedItem?.category === 'Dental Infection' && medRequest?.isPriority === false && isFeatureFlagEnabled

  const { parsedQuestions } = useQuestionnaire(medRequest?.questionaire ?? '')

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: ['aiSummary', medRequest?.id]
    })
  }

  const query = useQuery({
    queryKey: ['aiSummary', medRequest?.id],
    queryFn: async () => {
      if (medRequest?.orderMedNameString && parsedQuestions.length > 0) {
        const { prompt, summary } = (await http.get(`/prescriber/dental-ai/${medRequest.id}`)) as {
          prompt: string
          summary: string
        }

        const [mainText, attentionText] = summary.split('<PATIENT_FLAGS_MAY_NEED_ATTENTION>')

        return {
          attentionText: (attentionText ?? '')
            .replace('<PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
            .replace('</PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
            .split('\n')
            .filter(Boolean),
          mainText: (mainText ?? '').split('\n').filter(Boolean),
          prompt: prompt,
          summary: summary
        }
      }

      return {
        attentionText: [],
        mainText: [],
        prompt: '',
        summary: ''
      }
    },
    enabled: isEnabled
  })

  return {
    ...query,
    mainText: query.data?.mainText ?? [],
    attentionText: query.data?.attentionText ?? [],
    summary: query.data?.summary ?? '',
    prompt: query.data?.prompt ?? '',
    invalidate
  }
}
