import AdditionalNotes from './sections/additional-notes'
import Consultation from './sections/consultation'
import ContactPatientSection from './sections/contact-patient-section'
import Footer from './sections/footer'
import NavigationMenu from './sections/nav-menu'
import OnHoldHistory from './sections/on-hold-history'
import PatientData from './sections/patient-data'
import PrescriptionDetails from './sections/prescription-details'
import PreviousOrders from './sections/previous-orders'
import RejectionReasonSection from './sections/rejection-reason-section'

import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { getCurrentUKDate, isMedRequestInEditableStage } from '@/lib/utils'
import PostOrderView from './post-order-view'
import ApprovalReasonSection from './sections/approval-reason-section'
import Header from './sections/header'
import SCRDataSection from './sections/scr-data'
import PreviousInvestigations from './sections/previous-investigations'
import { useConsultationAISummary } from '@/hooks/useConsultationAISummary'
import SafeguardingQuestions from './sections/safeguarding-questions'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isRoleAllowedToActionMedRequest, isRoleAllowedToEditMedRequest } from '@/lib/permissions'
import { usePosthogCustom } from '@/hooks/usePosthogCustom'

const MedicationRequestPage = () => {
  const { sidebarView, status, setSidebarView, setMedicationIssueDate } = useMedicationRequestStore()

  const { trackMedRequestViewed } = usePosthogCustom()

  const { id } = useParams<{ id: string }>()
  useEffect(() => {
    setSidebarView('navigation-menu')
    setMedicationIssueDate(getCurrentUKDate())
    trackMedRequestViewed()
  }, [id])

  const medRequest = useCurrentMedication()
  const data = medRequest?.data

  const isEditable = (data && isMedRequestInEditableStage(data.stage) && isRoleAllowedToEditMedRequest(data)) ?? false

  const success = status !== 'pending'

  const hideFooter = sidebarView !== 'navigation-menu' || !isEditable || !isRoleAllowedToActionMedRequest(data)

  // Handle batch-approval scroll to top.
  useEffect(() => {
    if (status === 'approved') {
      window.scrollTo(0, 0)
    }
  }, [status])

  if (success) return <PostOrderView />
  if (!data) return null

  return (
    <section key={data.id} className='flex flex-col gap-8'>
      <Header />

      <div className='flex gap-[37px] flex-nowrap w-full justify-between'>
        <div className='flex flex-col gap-14 w-full'>
          <PatientData />
          <ErrorBoundary fallback='Error Loading Safeguarding Questions'>
            <SafeguardingQuestions isEditable={isEditable} />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error Loading Previous Orders'>
            <PreviousOrders patientId={data.patient.id} />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error Loading Previous Investigations'>
            <PreviousInvestigations patientId={data.patient.id} />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading On Hold History'>
            <OnHoldHistory />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading Consultation Data'>
            <Consultation />
          </ErrorBoundary>

          {!data.OTC && (
            <ErrorBoundary fallback='Error loading SCR Data'>
              <SCRDataSection />
            </ErrorBoundary>
          )}

          <PrescriptionDetails isEditable={isEditable} />
          <AdditionalNotes isEditable={isEditable} />
        </div>
        {sidebarView === 'navigation-menu' && (
          <ErrorBoundary fallback='Error loading Navigation Menu'>
            <NavigationMenu patientId={data.patient.id} />
          </ErrorBoundary>
        )}
        {sidebarView === 'approval-reason' && <ApprovalReasonSection />}
        {sidebarView === 'contact-patient' && <ContactPatientSection />}
        {sidebarView === 'rejection' && <RejectionReasonSection />}
      </div>
      {!hideFooter && <Footer />}
    </section>
  )
}

export default MedicationRequestPage
